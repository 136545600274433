import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { Product } from "../../../../business/interfaces/Product";
import { ErrorHandling } from "../../../../common/classes/ErrorHandling";
import {
  ActionRow,
  FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../../../components/table-view/table-view.component";
import { Website } from "../../../../business/interfaces/Website";
import { ActivatedRoute } from "@angular/router";
import { AppNavigator } from "../../../../services/app-navigator";
import { ProductAPI } from "../../../../business/apis/ProductAPI";
import { TableAction } from "../../../../components/table-view/enum/TableAction";
import { WebsiteNameId } from "../../../../business/name-id/WebsiteNameId";
import { InputField } from "../../../interfaces/InputField";
import { getEnumFromValue } from "../../../../common/utilities/General";
import { isFormSubmitted, resetForm } from "../../../../common/utilities/FormsHelper";
import { InputFieldType } from "../../../../components/input/enum/InputFieldType";
import { FormGroup, Validators } from "@angular/forms";
import { UrlValidator } from "../../../../components/input/validators/url-validator";
import { Image } from "../../../../business/interfaces/Image";
import { MediaType } from "../../../../business/enums/MediaType";

@Component({
  selector: 'product-videos',
  templateUrl: './product-videos.component.html',
  styleUrls: ['./product-videos.component.css']
})
export class ProductVideosComponent {

  @Input() loadData: BehaviorSubject<any> | null = null;
  @Output() reloadData = new EventEmitter<string>();

  productDetails: Product | null = null;

  form: FormGroup = new FormGroup({});

  videoFields: InputField[] = [];
  urlItem!: InputField;

  tableConfiguration: TableConfiguration<Website> = {};
  loadTableData: BehaviorSubject<[any, any]> = new BehaviorSubject<[any, any]>([[], null]);


  isScreenLoading: boolean = false;
  isLoading = false;
  errorHandling: ErrorHandling | null = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private productApi: ProductAPI
  ) {
  }

  ngOnInit(): void {
    this.videoFields = this.getInputFields();

    this.tableConfiguration = {
      hasFilter: false,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadTableData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };

    this.loadData!.subscribe((data) => {
      this.productDetails = data;
      this.reloadDataViews();
      this.isScreenLoading = false;
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.loadTableData?.unsubscribe();
  }

  getInputFieldById(id: WebsiteNameId): InputField | undefined {
    let item = this.videoFields.find((field) => field.id === id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId = getEnumFromValue(WebsiteNameId, id) as WebsiteNameId;
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): Object {
    let fieldId = getEnumFromValue(WebsiteNameId, id) as WebsiteNameId;
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  restError() {
    this.errorHandling = null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.saveProductWebsite();
    }
  }

  saveProductWebsite() {
    this.restError();
    this.isLoading = true;
    let productId = this.productDetails?.id ?? "";

    let url = this.getValueFieldById(WebsiteNameId.URL)?.toString()
    this.productApi
      .addVideo({
        productId: productId,
        type: MediaType.VIDEO,
        url: url,
      })
      .subscribe({
        next: (item) => {
          this.reloadData!.emit(this.productDetails?.id);
          this.resetFields()
        },
        error: (error: any) => {
          this.checkError(error);
          this.isLoading = false;
        },
      });
  }

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  reloadDataViews() {
    let list = this.productDetails?.images ?? [];
    list = list.filter(item => item.type === MediaType.VIDEO)
    this.tableConfiguration.loadData?.next([list, null]);
  }

  getActionRowTable<T>(item: T): ActionRow[] {
    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };
    return [deleteItem]
  }

  getColumTableInfo(): TableColumnInfo[] {


    let URL = {
      columId: WebsiteNameId.URL,
      name: "Video Url",
    };

    return [
      URL,
    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == WebsiteNameId.ID) {
      this.onTapImageDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let url = item as Image;
    let value = url[id as keyof Image];
    let styleClass = null;

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
  }

  private getInputFields(): InputField[] {
    this.urlItem = {
      id: WebsiteNameId.URL,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Video Url",
      value: "",
      validators: [Validators.required, new UrlValidator().validator],
      loadData: new BehaviorSubject<any>(""),
    } as InputField;

    return [this.urlItem];
  }

  private resetFields() {
    for (const field of this.videoFields) {
      field.loadData?.next("");
    }
    resetForm(this.form)
  }

  private onTapAction<T>(action: any, item: T) {
    let WebsiteAction = action as TableAction;
    let media = item as Image;
    let call: Observable<any>;

    if (WebsiteAction == TableAction.REMOVE) {
      let productId = this.productDetails?.id ?? ""
      let imageId = media.id
      call = this.productApi.deleteVideo({
        productId: this.productDetails?.id ?? "",
        id: imageId });
    } else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        this.reloadData!.emit(this.productDetails?.id);
      },
      error: (error: any) => {
      },
    });
  }

  private onTapImageDetails<T>(item: T) {
  }

  private reloadDataTable<T>(filterData: FilterTable) {
  }
}
