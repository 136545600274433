import {Phone} from "./Phone";


export enum MerchantNameId {
  id = 'id',
  nameEn = 'nameEn',
  nameAr = 'nameAr',
  briefEn = 'briefEn',
  briefAr = 'briefAr',
  taxIdentificationNumber = 'taxIdentificationNumber',
  slug = 'slug',
  pickupAvailable = 'pickupAvailable',
  deliveryAvailable = 'deliveryAvailable',
  profilePictureURL = 'profilePictureURL',
  coverPhotoURL = 'coverPhotoURL',
  createdAt = 'createdAt',
  isDeleted = 'isDeleted',
  status = 'status',
  isApproved = 'isApproved',
  isRejected = 'isRejected',
  isBlocked = 'isBlocked',
}



export interface Merchant {
  id?: string
  profilePictureURL?: any
  coverPhotoURL?: any
  nameEn?: string
  nameAr?: string
  briefEn?: string
  briefAr?: string
  taxIdentificationNumber?: string
  slug?:string
  deliveryAvailable?: string
  pickupAvailable?: string
  websites?: any[]
  images?: any[]
  menuItems?: any[]
  phones?: Phone[]
  socialMediaPlatforms?: any[]
  deliveryAreas?: any[]
  addresses?: any[]
  geoLocations?: any[]
  ratings?: any[]
  createdAt?: string
  isApproved?: boolean
  isRejected?: boolean
  isPublished?: boolean
  isBlocked?: boolean
  isDeleted?: boolean
}
