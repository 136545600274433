export enum APIType {
  REFRESH_TOKEN,
  LOGIN,
  REGISTER,

  addMerchant,
  getMerchantDetails,
  ADD_MERCHANT_AREA,
  DELETE_MERCHANT_AREA,
  ADD_MERCHANT_ADDRESS,
  DELETE_MERCHANT_ADDRESS,
  ADD_MERCHANT_PHONE,
  resendMerchantVerificationSMSCode,
  verifyMerchantCode,

  DELETE_MERCHANT_PHONE,
  ADD_MERCHANT_WEBSITE,
  DELETE_MERCHANT_WEBSITE,
  ADD_MERCHANT_SOCIAL_MEDIA_PLATFORM,
  DELETE_MERCHANT_SOCIAL_MEDIA_PLATFORM,
  ADD_MERCHANT_GEOLOCATION,
  DELETE_MERCHANT_GEOLOCATION,
  ADD_MERCHANT_PRODUCT,
  Get_MERCHANT_PRODUCTS,
  Get_MERCHANT_Users,
  ADD_MERCHANT_IMAGE,
  ADD_MERCHANT_VIDEO,
  DELETE_MERCHANT_IMAGE,
  DELETE_MERCHANT_VIDEO,

  ADD_MENU_ITEM,
  DELETE_MENU_ITEM,


  ADD_CATEGORY,
  GET_LIST_CATEGORIES,
  GET_CATEGORY_DETAILS,

  ADD_CITY,
  GET_LIST_CITIES,
  GET_CITY_DETAILS,

  ADD_COUNTRY,
  GET_LIST_COUNTRIES,
  GET_COUNTRY_DETAILS,


  ADD_CURRENCY,
  GET_LIST_CURRENCIES,
  GET_CURRENCY_DETAILS,


  ADD_AREA,
  GET_LIST_AREAS,
  GET_AREA_DETAILS,
  DELETE_AREA,


  GET_PRODUCT_DETAILS,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,

  ADD_PRODUCT_IMAGE,
  DELETE_PRODUCT_IMAGE,
  ADD_PRODUCT_VIDEO,
  DELETE_PRODUCT_VIDEO,



  ADD_USER,
  GET_USER_DETAILS,
  DELETE_USER,
  BLOCK_USER,
  UNBLOCK_USER,
  GET_FIREBASE_TOKEN,

  GET_ACCOUNT_INFO,
  UPDATE_ACCOUNT_INFO,
  UPDATE_PASSWORD,

  GET_ALL_MERCHANT_RATE,
  GET_MERCHANT_RATE,

  GET_ALL_PRODUCT_RATE,
  GET_PRODUCT_RATE,
  GET_MERCHANT_PRODUCT_RATE,

  APPROVE_RATE,
  REJECT_RATE,
  DELETE_RATE,

  GET_RATE_DETAILS,

  ADD_REPLAY_RATE,
  DELETE_REPLAY_RATE,

}



